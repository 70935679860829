import { css } from '@emotion/react';
import {
  ActionIcon,
  Box,
  desktopFirstMediaQuery,
  Text,
  Title,
  Tooltip,
  useTheme
} from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GATSBY_CDN_URL } from '../../../../../utils/env';
import { SHARE_MAIN_URL } from '../../../commons/constants';
import { styleSection } from '../InteractionSections';
import { commonSectionItemVariants } from '../InteractionSections/variants';
import { styleCommonSectionDescription, styleCommonSectionTitle } from '../SNS';

export const URL_CDN_IMAGE = (fileName: string) =>
  `https://cdn.inflearn.com/infcon/infcon-2023/${fileName}`;
const SHARE_TITLE = 'INFCON 2023';
const SHARE_DESCRIPTION = '배우고 나누고 성장하세요.';
const Share = () => {
  const theme = useTheme();

  return (
    <motion.section
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      css={[styleSection, styleShareSection]}>
      <video
        src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-share-bg.mp4`}
        autoPlay
        loop
        muted
        playsInline
        css={{ zIndex: -1, position: 'absolute', height: '100%', maxWidth: '100%', top: 0 }}
      />
      <Box
        css={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundColor: 'rgba(255,255,255,0.3)'
        }}></Box>
      <motion.div variants={commonSectionItemVariants()}>
        <Title order={2} color={theme.colors.dark[9]} css={styleCommonSectionTitle}>
          소식 공유하기
        </Title>
      </motion.div>
      <motion.div variants={commonSectionItemVariants({ delay: 0.2 })}>
        <Text
          color={theme.colors.gray[7]}
          align="center"
          css={[
            styleCommonSectionDescription,
            {
              marginBottom: '40px',
              [desktopFirstMediaQuery.mobile]: {
                marginBottom: '24px'
              }
            }
          ]}>
          인프콘 2023, 같이 가고 싶은 친구에게 얼른 알려주세요 =3
        </Text>
      </motion.div>
      <ShareButtonGroup />
    </motion.section>
  );
};

export const ShareButtonGroup = () => {
  return (
    <motion.div css={styleShareWrapper} variants={commonSectionItemVariants({ delay: 0.4 })}>
      <ShareByKaKaoTalk />
      <ShareByFacebook />
      <ShareByTwitter />
      <ShareByLink />
    </motion.div>
  );
};

export const ShareByKaKaoTalk = () => {
  const handleClick = () => {
    const kakao = window.Kakao;
    if (kakao === undefined || kakao === null) {
      return;
    }

    if (kakao.isInitialized() === false) {
      kakao.init(process.env.GATSBY_KAKAO_KEY ?? '');
    }

    kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: SHARE_TITLE,
        description: SHARE_DESCRIPTION,
        imageUrl: URL_CDN_IMAGE('main-share-kakao-800.png'),
        link: { mobileWebUrl: SHARE_MAIN_URL, webUrl: SHARE_MAIN_URL }
      }
    });
  };

  return (
    <ActionIcon
      name="카카오톡으로 공유하기"
      size={60}
      onClick={handleClick}
      css={styleMonoActionIcon}>
      <IconKakao />
    </ActionIcon>
  );
};

export const ShareByFacebook = () => {
  const handleClick = () => {
    const url =
      'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(SHARE_MAIN_URL);
    window.open(url, '');
  };

  return (
    <ActionIcon
      name="페이스북으로 공유하기"
      size={60}
      onClick={handleClick}
      css={styleMonoActionIcon}>
      <IconFacebook />
    </ActionIcon>
  );
};

export const ShareByLink = () => {
  const [opened, setOpened] = useState(false);

  return (
    <CopyToClipboard
      text={SHARE_MAIN_URL}
      onCopy={() => {
        setOpened(true);
        setTimeout(() => {
          setOpened(false);
        }, 1500);
      }}>
      <Tooltip label="링크 복사 완료!" color="black" opened={opened} position={'bottom'} withArrow>
        <ActionIcon name="링크 복사하기" size={60} css={styleMonoActionIcon}>
          <IconLink />
        </ActionIcon>
      </Tooltip>
    </CopyToClipboard>
  );
};

export const ShareByTwitter = () => {
  const handleClick = () => {
    const url = `https://twitter.com/intent/tweet?url=${SHARE_MAIN_URL}`;
    window.open(url, 'tweetPop');
  };
  return (
    <ActionIcon name="트위터로 공유하기" size={60} onClick={handleClick} css={styleMonoActionIcon}>
      <IconTwitter />
    </ActionIcon>
  );
};

const IconTwitter = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM39.5156 25.7812V25.125C40.4531 24.4219 41.2969 23.5781 41.9531 22.5938C41.1094 22.9688 40.125 23.25 39.1406 23.3438C40.1719 22.7344 40.9219 21.7969 41.2969 20.625C40.3594 21.1875 39.2812 21.6094 38.2031 21.8438C37.2656 20.8594 36 20.2969 34.5938 20.2969C31.875 20.2969 29.6719 22.5 29.6719 25.2188C29.6719 25.5938 29.7188 25.9688 29.8125 26.3438C25.7344 26.1094 22.0781 24.1406 19.6406 21.1875C19.2188 21.8906 18.9844 22.7344 18.9844 23.6719C18.9844 25.3594 19.8281 26.8594 21.1875 27.75C20.3906 27.7031 19.5938 27.5156 18.9375 27.1406V27.1875C18.9375 29.5781 20.625 31.5469 22.875 32.0156C22.5 32.1094 22.0312 32.2031 21.6094 32.2031C21.3182 32.2031 21.0639 32.1662 20.781 32.1251C20.7451 32.1199 20.7088 32.1147 20.6719 32.1094C21.2812 34.0781 23.1094 35.4844 25.2656 35.5312C23.5781 36.8438 21.4688 37.6406 19.1719 37.6406C18.75 37.6406 18.375 37.5938 18 37.5469C20.1562 38.9531 22.7344 39.75 25.5469 39.75C34.5938 39.75 39.5156 32.2969 39.5156 25.7812Z"
        fill="#212529"
      />
    </svg>
  );
};

const IconKakao = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM41.9812 28.75C41.9812 23.4062 36.6374 19 30.028 19C23.3718 19 18.028 23.4062 18.028 28.75C18.028 31.0938 19.0124 33.2031 20.653 34.8906C20.1702 36.9425 18.6514 38.7527 18.2308 39.2541C18.1613 39.3369 18.1218 39.384 18.1218 39.3906C17.9812 39.4844 17.9812 39.6719 18.028 39.8125C18.0749 39.9531 18.2155 40 18.403 40C21.4968 40 23.7937 38.5469 24.9655 37.6094C26.5124 38.1719 28.1999 38.5 30.028 38.5C36.6374 38.5 41.9812 34.1406 41.9812 28.75Z"
        fill="#212529"
      />
    </svg>
  );
};

const IconFacebook = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM31.7812 41.4844C37.3594 40.6406 41.625 35.8125 41.625 30C41.625 23.5781 36.4219 18.375 30 18.375C23.5781 18.375 18.375 23.5781 18.375 30C18.375 35.8125 22.5938 40.6406 28.1719 41.4844V33.375H25.2188V30H28.1719V27.4688C28.1719 24.5625 29.9062 22.9219 32.5312 22.9219C33.8438 22.9219 35.1562 23.1562 35.1562 23.1562V26.0156H33.7031C32.25 26.0156 31.7812 26.9062 31.7812 27.8438V30H35.0156L34.5 33.375H31.7812V41.4844Z"
        fill="#212529"
      />
    </svg>
  );
};

const IconLink = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM32.8164 24.1876C30.1914 21.5626 25.8789 21.5626 23.2539 24.1876L18.0039 29.4845C15.332 32.1095 15.332 36.422 18.0039 39.047C20.3477 41.3907 24.0039 41.7188 26.7227 39.797L26.8164 39.7501C27.4727 39.2345 27.6133 38.297 27.1445 37.6407C26.6758 36.9376 25.7383 36.797 25.0352 37.2657L24.9883 37.3595C23.4883 38.3907 21.4258 38.2501 20.1133 36.9376C18.6133 35.4376 18.6133 33.047 20.1133 31.5938L25.3633 26.3438C26.8633 24.8438 29.2539 24.8438 30.707 26.3438C32.0195 27.6563 32.207 29.672 31.1289 31.2188L31.082 31.2657C30.5664 31.922 30.7539 32.8595 31.4102 33.3751C32.1133 33.8438 33.0508 33.7032 33.5195 33.0001L33.5664 32.9532C35.4883 30.2345 35.1602 26.5313 32.8164 24.1876ZM27.5195 35.8126C30.1445 38.4845 34.457 38.4845 37.082 35.8126L42.332 30.5626C45.0039 27.9376 45.0039 23.6251 42.332 21.0001C39.9883 18.6563 36.332 18.3282 33.6133 20.2501L33.5195 20.297C32.8633 20.8126 32.7227 21.7501 33.1914 22.4063C33.6602 23.0626 34.5977 23.2501 35.3008 22.7345L35.3477 22.6876C36.8477 21.6095 38.9102 21.797 40.2227 23.1095C41.7227 24.5626 41.7227 26.9532 40.2227 28.4532L34.9727 33.7032C33.4727 35.2032 31.082 35.2032 29.6289 33.7032C28.3164 32.3907 28.1289 30.3282 29.207 28.8282L29.2539 28.7813C29.7695 28.0782 29.582 27.1407 28.9258 26.672C28.2227 26.2032 27.2852 26.3438 26.8164 27.047L26.7695 27.0938C24.8477 29.8126 25.1758 33.4688 27.5195 35.8126Z"
        fill="#212529"
      />
    </svg>
  );
};

const styleShareSection = css({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '200px 32px 300px 32px',
  [desktopFirstMediaQuery.mobile]: {
    height: '450px'
  }
});

const styleShareWrapper = css({
  display: 'flex',
  flexFlow: 'row wrap',
  columnGap: 16
});

const styleMonoActionIcon = css({
  borderRadius: '50%',
  color: 'white',
  transition: 'transform .25s ease-in-out',
  ':hover': {
    backgroundColor: 'transparent'
  },
  ':pressed': {
    transform: 'translateY(2px)'
  }
});

export default Share;
